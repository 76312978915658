$(function () {
    /* 
     Custom js file for assan
     */
     $('#myModal').on('show.bs.modal', function (event) {
      var button = $(event.relatedTarget)
      var recipient = button.data('title')
      var body_text = button.data('body_text')
      var body_img = button.data('body_img')
      var body_img_width = button.data('body_img_widht')
      var body_img_height = button.data('body_img_height')
      
      if (recipient ==  "Nutrition Bot")
        var text = "<h4><b>Thématique :</b> <a href='https://etna.io/formation/specialisations-metiers/'>Ingénierie du big data et du machine learning</a> </h4><br><h7><b>Sujet :</b> L'objectif de ce projet est de développer un bot nutritionnel permettant de gérer repas et activité physique au quotidien. Pour ce faire, différents capteurs, un robot (vocal et textuel) et une application client permettant de donner ses propres mesures sont à la disposition de l'utilisateur. Le projet a aussi pour but final d'intégrer une intelligence permettant de conseiller les utilisateurs sur leur façon de se nourrir, grâce à des informations vérifiées par des nutritionnistes.</h7>";
      else if (recipient ==  "Vizixx")
        var text = "<h4><b>Thématique :</b> <a href='https://etna.io/formation/specialisations-metiers/'>Ingénierie des systèmes numériques embarqués et objets connectés</a> </h4><br><h7><b>Sujet :</b> Ce projet a pour but de mettre un système de visualisation de données pour des capteurs mis en place au japon. Les capteurs utilisent la technologie LORA. Ainsi, un catalogue de déchiffrage des trames envoyées par les capteurs doit être mis en place. Nous nous rapprochons de fonctionnalités d'outils tel que Grafana avec notre propre algorithme de déchiffrage de capteur.</h7>"
      else if (recipient ==  "Serres hydroponiques")
        var text = "<h4><b>Thématique :</b> <a href='https://etna.io/formation/specialisations-metiers/'>Ingénierie des systèmes numériques embarqués et objets connectés</a> </h4><br><h7><b>Sujet :</b> Ce projet est en collaboration avec le ministère de l'industrie. L'objectif est d'utiliser un kit d'hydroponie IKEA et de le rendre autonome et connecté. Nous avons utilisé un Raspberry Pi avec une multitude de capteurs (mesurant la température, l'humidité, la luminosité...) tout ceci en plus d'un affichage vidéo en timelapse pour voir l'évolution de ces plantes. Grâce à notre interface web, nous pouvons suivre l'évolution des plantes à partir de la plantation des graines jusqu'au moment de la récolte.</h7>"
      else if (recipient ==  "TaxyMatch")
        var text = "<h4><b>Thématique :</b> <a href='https://etna.io/formation/specialisations-metiers/'>Ingénierie du développement d’application mobile</a> </h4><br><h7><b>Sujet :</b> L'application Taxymatch met en relation des particuliers pour partager un taxi à prix réduit. Par exemple, si un trajet en taxi de Ivry-Sur-Seine à Orly coûte 40€ pour une personne, en trouvant 3 autres personnes faisant le même trajet vous pourriez payer seulement 10€ environ par personne.</h7>"
      else if (recipient ==  "ETNA Coin")
        var text = "<h4><b>Thématique :</b> <a href='https://etna.io/formation/specialisations-metiers/'>Ingénierie de la blockchain</a> </h4><br><h7><b>Sujet :</b> La crypto monnaie ETNA Coin a été créée dans le Co-Labs à la suite de plusieurs tests. Celle-ci est basée sur un modèle ERC-20. Le but de cette crypto monnaie est de montrer aux étudiants qu'une monnaie n'est pas forcément que physique, et qu'une monnaie virtuelle pouvait avoir une valeur supérieur à une monnaie physique. Aussi, la crypto monnaie peut avoir une utilité bien plus diverse que son homologue physique.</h7>"
      else if (recipient ==  "Jena VR")
        var text = "<h4><b>Thématique :</b> <a href='https://etna.io/formation/specialisations-metiers/'>Ingénierie de la réalité virtuelle, augmentée et jeux vidéo</a> </h4><br><h7><b>Sujet :</b> Le projet JenaVR a été développé au sein du Co-Labs pour montrer le potentiel de la réalité virtuelle aux étudiants. C'est un projet développé par des étudiants pendant leur année préparatoire. L'objectif est de recréer l'univers d'un escape game dans un monde virtuel.</h7>"
      var modal = $(this)
      modal.find('.modal-title').text(recipient)
      modal.find('.modal-body-text').html(text)
      modal.find('.modal-body-img').attr("width", body_img_width);
      modal.find('.modal-body-img').attr("height", body_img_height);
      modal.find('.modal-body-img').attr("src", body_img);
    })
//preloader
    $(window).preloader({
        delay: 500
    });
   $(".sticky-nav").sticky({topSpacing:0});
//shrink header
    $(document).on("scroll", function () {
        if
                ($(document).scrollTop() > 150) {
            $(".navbar-transparent").addClass("fixed-top");
        } else
        {
            $(".navbar-transparent").removeClass("fixed-top");
        }
    });
//back to top
    if ($('#back-to-top').length) {
        var scrollTrigger = 100, // px
                backToTop = function () {
                    var scrollTop = $(window).scrollTop();
                    if (scrollTop > scrollTrigger) {
                        $('#back-to-top').addClass('show');
                    } else {
                        $('#back-to-top').removeClass('show');
                    }
                };
        backToTop();
        $(window).on('scroll', function () {
            backToTop();
        });
        $('#back-to-top').on('click', function (e) {
            e.preventDefault();
            $('html,body').animate({
                scrollTop: 0
            }, 700);
        });
    }

    wow = new WOW(
            {
                boxClass: 'wow',
                animateClass: 'animated',
                offset: 0,
                mobile: true,
                live: true
            }
    );
    wow.init();

    //tooltip
    $('[data-toggle="tooltip"]').tooltip();
    //popover
    $('[data-toggle="popover"]').popover();
    //smooth scroll
    smoothScroll.init({
        selector: '[data-scroll]', // Selector for links (must be a class, ID, data attribute, or element tag)
        speed: 1000, // Integer. How fast to complete the scroll in milliseconds
        easing: 'easeInOutCubic', // Easing pattern to use
        offset: 52, // Integer. How far to offset the scrolling anchor location in pixels
        callback: function (anchor, toggle) {} // Function to run after scrolling
    });
    //auto close navbar-collapse on click a
    $('.navbar a.nav-link').on('click', function () {
        $('.navbar-toggler:visible').click();
    });
    /**youtube video popup**/
    $('.modal-video').magnificPopup({
        type: 'iframe'
    });
    //knob circle progress bar
    $(".progress-circle").knob();
});
